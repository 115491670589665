<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('tpm_report.training_course_summary_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-overlay>
                  <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.fiscal_year')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1" >
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="org_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.organization')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.org_id"
                          :options="orgList"
                          id="org_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="batch_no"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.batch_no')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.batch_no"
                          :options="batchList"
                          id="batch_no"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="circular_memo_no"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.circular_memo_no')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.circular_memo_no"
                          :options="circularList"
                          id="circular_memo_no"
                          >
                          <template v-slot:first>
                              <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <div class="col-6">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="start_date"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.start_date')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.start_date"
                            :placeholder="$t('globalTrans.select_date')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="end_date"
                        >
                          <template v-slot:label>
                            {{$t('globalTrans.end_date')}}
                          </template>
                          <b-form-input
                              class="fromDate"
                              v-model="search.end_date"
                              :placeholder="$t('globalTrans.select_date')"
                          ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <b-button type="submit" variant="primary" class="mr-2"  @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </div>
                  </b-row>
                </b-overlay>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12" v-if="showData">
            <b-overlay>
              <b-row v-if='datas.length > 0'>
                <b-col md="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('tpm_report.training_course_summary_report') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button class="btn_add_new" @click="pdfExport">
                        <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay>
                        <b-row mt-5>
                          <b-col md="12" class="table-responsive">
                            <div style="border: 2px solid;margin:10px;padding:10px">
                              <b-row>
                                <b-col>
                                  <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                    <template v-slot:projectNameSlot>
                                      {{ }}
                                    </template>
                                    {{ $t('tpm_report.training_course_summary_report') }}
                                  </list-report-head>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                    <div class="text-center">
                                        <table style="width:100%;color:black;">
                                            <tr v-if="search.fiscal_year">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                            </tr>
                                            <tr v-if="search.org_id">
                                              <td align="right" style="width:45%">{{ $t('elearning_config.organization') }}</td>
                                              <td align="center" style="width:5%">:</td>
                                              <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.org_name_bn : search.org_name }}</td>
                                            </tr>
                                            <tr v-if="search.training_type">
                                              <td align="right" style="width:45%">{{ $t('elearning_config.training_type') }}</td>
                                              <td align="center" style="width:5%">:</td>
                                              <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_type_bn : search.training_type }}</td>
                                            </tr>
                                            <tr v-if="search.training_category">
                                              <td align="right" style="width:45%">{{ $t('elearning_config.training_category') }}</td>
                                              <td align="center" style="width:5%">:</td>
                                              <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_category_bn : search.training_category }}</td>
                                            </tr>
                                            <tr v-if="search.training_title">
                                              <td align="right" style="width:45%">{{ $t('elearning_config.training_title') }}</td>
                                              <td align="center" style="width:5%">:</td>
                                              <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_title_bn : search.training_title }}</td>
                                            </tr>
                                            <tr v-if="search.batch_no">
                                              <td align="right" style="width:45%">{{ $t('elearning_iabm.batch_no') }}</td>
                                              <td align="center" style="width:5%">:</td>
                                              <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.batch_name_bn : search.batch_name }}</td>
                                            </tr>
                                            <tr v-if="search.training_quarter_id">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.training_quarter_name') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.quaeter_name_bn : search.quaeter_name }}</td>
                                            </tr>
                                            <tr v-if="search.circular_memo_no">
                                                <td align="right" style="width:45%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ search.circular_memo_no }}</td>
                                            </tr>
                                            <tr v-if="search.course_id">
                                                <td align="right" style="width:45%">{{ $t('globalTrans.course') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.course_name_bn : search.course_name }}</td>
                                            </tr>
                                            <tr v-if="search.start_date">
                                                <td align="right" style="width:45%">{{ $t('globalTrans.start_date') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ search.start_date | dateFormat }}</td>
                                            </tr>
                                            <tr v-if="search.end_date">
                                                <td align="right" style="width:45%">{{ $t('globalTrans.end_date') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ search.end_date | dateFormat }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="datas.length > 0">
                                    <b-thead>
                                      <b-tr>
                                        <b-th style="width:8%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th style="width:12%" class="text-center">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                        <b-th style="width:13%" class="text-center">{{ $t('org_pro.organization') }}</b-th>
                                        <b-th class="text-center">{{$t('elearning_config.training_title')}}</b-th>
                                        <b-th style="width:10%" class="text-center">{{ $t('elearning_iabm.batch_no') }}</b-th>
                                        <b-th style="width:10%" class="text-center">{{ $t('elearning_iabm.circular_memo_no') }}</b-th>
                                        <b-th class="text-center">{{$t('tpm_report.speaker_communication')}}</b-th>
                                        <b-th class="text-center">{{$t('tpm_report.course_name')}}</b-th>
                                        <b-th style="width:11%" class="text-center">{{$t('tpm_report.total_lession')}}</b-th>
                                      </b-tr>
                                    </b-thead>
                                    <b-tbody v-for="(info, index) in datas" :key="index">
                                      <b-td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.fiscal_year_bn : info.fiscal_year }}</b-td>
                                      <b-td class="text-center">{{ info.profession_type === 1 ? (($i18n.locale==='bn') ? info.org_bn : info.org) : (($i18n.locale==='bn') ? info.other_org_name_bn : info.other_org_name) }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.training_title_bn : info.training_title }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.batch_name_bn : info.batch_name }}</b-td>
                                      <b-td class="text-center">{{ info.circular_memo_no }}</b-td>
                                      <b-td class="text-center">{{ (($i18n.locale==='bn') ? info.name_bn : info.name) + ', ' + (($i18n.locale==='bn') ? info.designation_name_bn : info.designation_name)
                                        + ', ' + ($i18n.locale === 'bn' ? '০' : '0') + $n(info.mobile, { useGrouping: false }) }}</b-td>
                                      <!-- <b-td class="text-center">
                                        <span v-for="(lesson, index1) in info.lessons" :key="index1">
                                            {{ ($i18n.locale==='bn') ? lesson.topics_session_bn : lesson.topics_session + ', '}}
                                        </span>
                                      </b-td> -->
                                      <b-td class="text-center">
                                        {{ getLessonName(info.lessons) }}
                                      </b-td>
                                      <b-td class="text-center">
                                        {{ $n(info.lessons.length) }}
                                      </b-td>
                                    </b-tbody>
                                  </b-table-simple>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col class="text-center" v-if="$i18n.locale==='bn' && datas.length > 0"><strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer1') }} {{ $t('tpm_report.report_footer2') }}</b-col>
                                <b-col class="text-center" v-if="$i18n.locale==='en' && datas.length > 0">{{ $t('tpm_report.report_footer1') }} <strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer2') }}</b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
        <!-- <pre>{{datas}}</pre> -->
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingCourseSummaryReport, circularList } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ListReportHead
    },
    created () {
        this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        })
    },
    data () {
        return {
          saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
          search: {
            circular_memo_no: '',
            org_id: 0,
            batch_no: 0,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            training_type: '',
            start_date: '',
            end_date: '',
            fiscal_year_id: 0
          },
          circularLoading: false,
          allBatchListData: [],
          trainingTypeList: [],
          batchList: [],
          circularList: [],
          trainingCategoryList: [],
          trainingTitleList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          personalInfoList: [],
          loading: false,
          showData: false
        }
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
    },
    computed: {
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainingQuarterSetupList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      i18 () {
        return this.$i18n.locale
      },
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      }
    },
  watch: {
    'search.org_id': function (newValue) {
      this.trainingTypeList = this.getTypeList(newValue)
    },
    'search.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'search.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'search.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCircularList()
      }
    },
    'search.fiscal_year_id': function (newValue) {
      this.getCircularList()
    },
    'search.circular_memo_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.search.batch_no = allBatchListData.batch_no
        }
      }
    },
    'search.batch_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.search.circular_memo_no = allBatchListData.circular_memo_no
        }
      }
    }
  },
    methods: {
      pdfExport () {
          const reportTitle = this.$t('tpm_report.training_course_summary_report')
          ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search)
      },
      getTypeList (orgId) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      },
      batchInfo () {
        const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.search.training_title_id))
        const batchList = []
        batchData.map((obj, index) => {
          if (obj.batch_no) {
            const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
            if (typeof isThere !== 'undefined') {
              batchList.push(isThere)
            }
          }
        })
        this.batchList = batchList
        if (this.batchList.length === 1) {
          this.search.batch_no = 1
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id,
          training_type_id: this.search.training_type_id,
          training_category_id: this.search.training_category_id,
          training_title_id: this.search.training_title_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          this.allBatchListData = result.batchList
          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      async searchData () {
        this.loading = true
        this.showData = true
        this.getCustomDataSearch()
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingCourseSummaryReport, this.search)
        if (result.success) {
          this.loading = false
          if (result.data) {
            this.personalInfoList = result.personalInfoList
            this.datas = this.getCustomDataList(result.data)
          }
        } else {
          this.datas = []
          this.loading = false
        }
      },
      getCustomDataSearch () {
        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYearList => fiscalYearList.value === parseInt(this.search.fiscal_year_id))
        if (typeof fiscalYearObj !== 'undefined') {
          this.search.fiscal_year = fiscalYearObj.text_en
          this.search.fiscal_year_bn = fiscalYearObj.text_bn
        //   this.search.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          this.search.fiscal_year = ''
          this.search.fiscal_year_bn = ''
        }

        const batchObj = this.allBatchList.find(item => item.value === this.search.batch_no)
        this.search.batch_name = typeof batchObj !== 'undefined' ? batchObj.text_en : ''
        this.search.batch_name_bn = typeof batchObj !== 'undefined' ? batchObj.text_bn : ''

        const orgObj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === this.search.org_id)
        this.search.org_name = typeof orgObj !== 'undefined' ? orgObj.text_en : ''
        this.search.org_name_bn = typeof orgObj !== 'undefined' ? orgObj.text_bn : ''

        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(item => item.value === this.search.training_type_id)
        this.search.training_type = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_en : ''
        this.search.training_type_bn = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_bn : ''

        const trainingCategoryListObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(item => item.value === this.search.training_category_id)
        this.search.training_category = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_en : ''
        this.search.training_category_bn = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_bn : ''

        const trainingTitleListObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === this.search.training_title_id)
        this.search.training_title = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_en : ''
        this.search.training_title_bn = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_bn : ''

        const tQuarterObj = this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.find(doc => doc.value === parseInt(this.search.training_quarter_id))
        if (typeof tQuarterObj !== 'undefined') {
            this.search.quaeter_name = tQuarterObj.text_en
            this.search.quaeter_name_bn = tQuarterObj.text_bn
        } else {
            this.search.quaeter_name = ''
            this.search.quaeter_name_bn = ''
        }
        const courseObj = this.$store.state.TrainingElearning.commonObj.courseList.find(doc => doc.value === parseInt(this.search.course_id))
        if (typeof courseObj !== 'undefined') {
            this.search.course_name = courseObj.text_en
            this.search.course_name_bn = courseObj.text_bn
        } else {
            this.search.course_name = ''
            this.search.course_name_bn = ''
        }
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const trainerObj = this.personalInfoList.find(doc => doc.value === item.trainer_id)
          const trainerData = {}
          if (typeof trainerObj !== 'undefined') {
            trainerData.trainer = trainerObj.text_en
            trainerData.trainer_bn = trainerObj.text_bn
          } else {
            trainerData.trainer = ''
            trainerData.trainer_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const designationObj = this.$store.state.commonObj.designationList.find(doc => doc.value === parseInt(item.designation_id))
          const designationData = {}
          if (typeof designationObj !== 'undefined') {
            designationData.designation = designationObj.text_en
            designationData.designation_bn = designationObj.text_bn
          } else {
            designationData.designation = ''
            designationData.designation_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }

          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const batchObj = this.allBatchList.find(doc => doc.value === parseInt(item.batch_no))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch_name = batchObj.text_en
            batchData.batch_name_bn = batchObj.text_bn
          } else {
            batchData.batch_name = ''
            batchData.batch_name_bn = ''
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, designationData, OfficeTypeData, trainingTitleData, trainingTypeData, trainingCategoryData, trainerData, batchData)
        })
        return listData
      },
      getLessonName (datas) {
        const lesson = []
        datas.map((item, index) => {
          if (this.$i18n.locale === 'bn') {
            lesson.push(item.topics_session_bn)
          } else {
            lesson.push(item.topics_session)
          }
        })
        return lesson.toString()
      }
    }
}
</script>
